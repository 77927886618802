// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const VERCEL_ENV = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

Sentry.init({
  dsn: SENTRY_DSN || 'https://9735b45c690d4252a1d9abfa80cdb194@o894200.ingest.sentry.io/5885304',
  tracesSampler(samplingContext) {
    if (samplingContext.transactionContext.op === 'pageload') {
      return 0.01;
    }

    return 0.2;
  },
  environment: VERCEL_ENV,
  enabled: VERCEL_ENV === 'production',
});
