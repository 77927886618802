import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import { WKGatherer } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { MyContext, IContextProps } from '../config/context-manager';
import '../styles/base.scss';

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { locale } = router;
  const value = { locale };

  useEffect(() => {
    WKGatherer({
      config: { env: process.env.NEXT_PUBLIC_STAGE || 'test' },
      data: { ak: 'landing' },
    });
  }, []);

  return (
    <MyContext.Provider value={value as IContextProps}>
      <Head>
        <title>悟空教育在线直播课</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      <Component {...pageProps} />
    </MyContext.Provider>
  );
};

export default appWithTranslation(App);
